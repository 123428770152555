@charset "UTF-8";

@font-face {
  font-family: "good2go2020";
  src:url("fonts/good2go2020.eot");
  src:url("fonts/good2go2020.eot?#iefix") format("embedded-opentype"),
  url("fonts/good2go2020.woff") format("woff"),
  url("fonts/good2go2020.ttf") format("truetype"),
  url("fonts/good2go2020.svg#good2go2020") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon] {
  &:before {
    font-family: "good2go2020" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

[class^="icon-"],
[class*=" icon-"] {
  &:before {
    font-family: "good2go2020" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    text-decoration: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.icon-twitter {
  &:before {
    content: "\61";
  }
}

.icon-facebook {
  &:before {
    content: "\62";
  }
}

.icon-youtube-alt {
  &:before {
    content: "\63";
  }
}

h1, .h1, h2, .h2, h3, .h3, h4, h5, h6 {
  color: $steel-blue;
  margin: 0 0 15px;
  font-weight: 400;
}

.inline-block {
  display:inline-block;
}

.nowrap {
  white-space: nowrap;
}

p, label, li {
  color: $dark-gray;
}

a {
  color: $green;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  font-weight: inherit;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: $dark-green;
    text-decoration: none;
  }
}

h1,
.h1 {
  font-size: 42px;
  line-height: 48px;
  letter-spacing: 0.93px;
  font-weight: 500;

  @include max($small) {
    font-size: 36px;
    line-height: 42px;
    letter-spacing: 0.8px;
  }

  &.large {
    font-size: 54px;
    line-height: 56px;
    letter-spacing: 1.2px;
    font-weight: 700;

    @include max($small) {
      font-size: 36px;
      line-height: 40px;
      letter-spacing: 0.8px;
      font-weight: 500;
    }
  }

  &.smaller-sm {
    @include max($small) {
      font-size: 28px;
      line-height: 33px;
      letter-spacing: 0.62px;
      font-weight: 400;
    }
  }

  sup {
    font-size: 22px;
  }
}

h2,
.h2 {
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 0.71px;

  @include max($small) {
    font-size: 28px;
    line-height: 37px;
    letter-spacing: 0.62px;
  }
}

h3,
.h3 {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.53px;

  @include max($small) {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0.49px;
  }

  &.smaller-sm {
    @include max($small) {
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.4px;
    }
  }
}

h4,
.h4 {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.4px;
  font-weight: 500;

  @include max($small) {
    font-size: 18px;
    line-height: 26px;
  }
}

h5,
.h5 {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.27px;
  font-weight: 500;
}

p {
  font-size: 16px;
  line-height: 26px;

  &.large {
    font-size: 18px;
    line-height: 28px;

    @include max($small) {
      font-size: 16px;
      line-height: 26px;
    }
  }

  &.small {
    font-size: 14px;
    line-height: 20px;
  }

  &.small-xs {
    @include max($small) {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.5px;
      margin: 0;
    }
  }

  &.legal {
    font-size:12px;
    line-height: 18px;
  }
}

label {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin: 0 0 8px;
}