// Mixins
@mixin min($min) {
  @media screen and (min-width: $min + 1)  {
    @content;
  }
}

@mixin max($max) {
  @media screen and (max-width: $max)  {
    @content;
  }
}

@mixin input-placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}