// Breakpoint sizes:
$xlarge: 1439px;
$large: 1199px;
$medium: 991px;
$small: 767px;
$xsmall: 414px;

// Fonts:
$roboto: "Roboto", sans-serif;
$source: "Source Sans Pro", sans-serif;
$arial: arial;

// Colors:
$white: #FFF;
$off-white: #F6F6F6;
$green: #327535;
$dark-green: #0D5A2D;
$steel-blue: #434F6B;
$dark-gray: #4B4B4D;
$medium-gray: #75787B;
$light-gray: #97999B;
$yellow: #FED141;
$red: #DA291C;
$light-red: #DA291C;
$teal: #04A4B1;
$input-text: #696C72;
$sky-blue: #e0f4fc;

// Gradients:
$gradient-green: linear-gradient(to top right, #76BC37, #1F8A5A);
$gradient-yellow: linear-gradient(to bottom right, #FED141, #FFB642);
$gradient-green-to-yellow: linear-gradient(to right, #429321, #B4EC51);

// Dashed line:
$dashed-divider: #FFB642;

// Buttons:
$yellow-button: linear-gradient(to top right, #FED141, #FFC242 44%, #FFB642);
$yellow-button-hover: #FFB43E;
$yellow-button-active: linear-gradient(to top right, #FFB642, #F08E00);

$green-button: linear-gradient(to top right, #76BC37, #1F8A5A);
$green-button-hover: #247A48;
$green-button-active: linear-gradient(to top right, #1F8A48, #0D5A2D);

// Other variables
$gutter: 48px;
$modal-z-index: 10010;