html {
  font-size: 16px;

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $roboto;
  }
}

// TODO - containersa
.container {
  width: 100%;
  max-width: 938px + $gutter;
  padding-right: ceil(($gutter / 2));
  padding-left: floor(($gutter / 2));

  .row {
    margin-right: floor(($gutter / -2));
    margin-left: ceil(($gutter / -2));

    [class*="col-"] {
      @include max($small) {
        padding-right: ($gutter / 2);
        padding-left: ($gutter / 2);
      }
    }
  }
}

.page-wrapper {
  padding-top: 1px;
  margin-top: 191px;
}

.solid-divider {
  margin: 10px auto;
  border-bottom: 1px solid $steel-blue;

  @include max($small) {
    margin: 24px auto;
  }
}

.dashed-divider {
  margin: 10px auto;
  height: 2px;
  background: repeating-linear-gradient(to right, $dashed-divider 0, $dashed-divider 16px,transparent 16px,transparent 48px);

  @include max($small) {
    margin: 24px auto;
  }
}

.background-color {
  &-white {
    background-color: $white;
  }

  &-off-white {
    background-color: $off-white;
  }
}

.button, .text-button, .arrow-button {
  &:focus {
    text-decoration: none;
  }
}

.text-centered {
  text-align: center;
}

.text-centered-desktop {
  @include min($small) {
    text-align: center;
  }
}

.no-margin {
  margin: 0;
}

.button {
  border: none;
  display: inline-block;
  text-align: center;
  line-height: 48px;
  width: 100%;
  max-width: 300px;
  padding: 0 15px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.3px;
  box-shadow: 0 2px 4px 0 rgba(147,149,152,0.30);
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &-yellow {
    color: $steel-blue;
    background: $yellow-button;

    &:hover {
      color: $steel-blue;
      background: $yellow-button-hover;
    }

    &:active {
      color: $steel-blue;
      background: $yellow-button-active;
    }

    &:focus {
      color: $steel-blue;
    }
  }

  &-green {
    color: $white;
    background: $green-button;

    &:hover {
      color: $white;
      background: $green-button-hover;
    }

    &:active {
      color: $white;
      background: $green-button-active;
    }

    &:focus {
      color: $white;
    }
  }
}

.arrow-button {
  display: inline-block;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.3px;
  color: $green;
  text-decoration: none;

  & > span {
    position: relative;
    padding-right: 30px;
    white-space: nowrap;
    color: inherit;

    &:after {
      background-image: url(../images/green-link-arrow.png);
      position: absolute;
      content: ' ';
      background-size: 100%;
      background-repeat: no-repeat;
      width: 22px;
      height: 13px;
      right: 0;
      top: calc(50% - 6px);
      transition: bottom 0.2s ease-in-out;
    }

  }

  &:hover, &:active {
    text-decoration: none;
    color: $dark-green;

    & > span {
      text-decoration: none;

      &:after {
        background-image: url(../images/dark-green-link-arrow.png);
      }
    }
  }
}

.text-button {
  display: inline-block;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.36px;
  border-bottom: 1px solid;
  text-decoration: none;

  &:hover, &:active {
    text-decoration: none;
  }

  &-green {
    color: $green;
    border-bottom-color: $green;

    &:hover, &:active, &:focus {
      color: $dark-green;
      border-bottom-color: $dark-green;
    }
  }

  &-white {
    color: $white;
    border-bottom-color: $white;

    &:hover, &:active, &:focus {
      color: $white;
      border-bottom-color: $white;
      // Todo - these colors needs to be changed
    }
  }

  &-light-gray {
    color: $light-gray;
    border-bottom-color: $light-gray;

    &:hover, &:active, &:focus {
      color: $light-gray;
      border-bottom-color: $light-gray;
      // Todo - these colors needs to be changed
    }
  }

  &-dark-gray {
    color: $dark-gray;
    border-bottom-color: $dark-gray;

    &.show-on-desktop {
      @include max($small) {
        display: none;
      }
    }

    &.show-on-mobile {
      display: none;

      @include max($small) {
        display: inline-block;
      }
    }

    &:hover, &:active, &:focus {
      color: $dark-gray;
      border-bottom-color: $dark-gray;
      // Todo - these colors needs to be changed
    }
  }

}

//set parent to position: relative;
.bg-image-container {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  picture {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;

    img {
      object-fit: cover;
      font-family: 'object-fit: cover;';
      width: 100%;
    }
  }
}

.visually-hidden {
  position: absolute;
  text-indent: -9999px;
  overflow: hidden;
}

.hp-only {
  display: none;
}
.page-id-720, .page-id-725 { //  homepage, car-insurance
  .hp-only {
    display: block;
  }
}

iframe {
  width: 100%;
  height: 100%;
  border: 0px;
}